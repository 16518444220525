import React from 'react';
import { graphql } from 'gatsby';
import { Paper, Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import { MdxArticle } from '../types/Article';
import Layout, { Wrapper } from '../layouts/Layout';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { Branding } from '../components/Branding';
import { Subheading } from '../components/Subheading';

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  grid-row-gap: ${(p) => p.theme.spacing(8)}px;

  & > div {
    margin-bottom: 12px;
  }
`;

const COLOR = '#ffffff';

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${COLOR};
    border-bottom: 2px solid #efefef;
  `};
`;

const StyledA = styled('a')`
  color: ${COLOR};
  border-bottom: 2px solid #efefef;
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Terms and Privacy Policy"
        siteUrl="https://bloggingfordevs.com"
        description="Terms and Conditions"
        pathname="terms/"
      />
      <div
        style={{
          maxWidth: '600px',
          padding: '16px',
          margin: '0 auto'
        }}
      >
        <Wrapper>
          <Branding to="/">Blogging for Devs</Branding>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            style={{ textAlign: 'center' }}
          >
            Terms and Privacy Policy
          </Typography>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ textAlign: 'center' }}
          >
            Hi there! I'm happy to have you on Blogging for Devs. This page is
            meant to inform you about the small number of services we use to
            keep to keep things humming, as well as how your data is used and
            how you can have it removed if you so please.
            <br />
            <br />
            You can also find a tiny number of terms of joining our community at
            the end of this document.
            <br />
            <br />
            Contact:{' '}
            <StyledA href="mailto:hi@bloggingfordevs.com">
              hi@bloggingfordevs.com
            </StyledA>
            <br />
            <br />
          </Typography>
          <Subheading>For Website Visitors</Subheading>
          <br />
          <Typography variant="h5" component="p" paragraph>
            First-Party Services
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We self-host the comments on the blog in Google Firebase. If you've
            posted a comment and would like to have it removed, please contact
            us.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            In order to inform you of replies to any anonymous comments, we
            collect your email address. Your email address will not be used for
            any kind of marketing, targeting, or anything shady like that.
          </Typography>
          <Typography variant="h5" component="p" paragraph>
            Third-Party Services
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We use <StyledA href="https://mailgun.com">Mailgun</StyledA> to
            email you about any replies to your comments. The email server we
            use with Mailgun is hosted in the EU.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We use{' '}
            <StyledA href="https://analytics.google.com">
              Google Analytics
            </StyledA>{' '}
            and turn off advertising features, IP tracking, and demographics
            collection for all visitors. We do not share personal data with
            Google Analytics.
          </Typography>
          <Typography variant="h5" component="p" paragraph>
            How your data is stored
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            These comments are self-hosted in Google Firebase, and not shared
            with any third-party provider. Your email address is stored, but not
            visible or accessible to website visitors. It is shared with
            Mailgun, only in the situation where someone replies to your
            comment.
          </Typography>
          <Subheading>For Newsletter Subscribers</Subheading>
          <br />
          <Typography variant="h6" component="p" paragraph>
            By joining our newsletter, you agree to receive email from us!
            Sounds obvious, but we gotta say it.
          </Typography>
          <Typography variant="h5" component="p" paragraph>
            Third-Party Services
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We use <StyledA href="https://convertkit.com">ConvertKit</StyledA>{' '}
            as our email service provider. As such, your email address and
            sometimes your name (if you provide it) is stored with them. If you
            wish to unsubscribe from the newsletter, you can do so using the
            link in the footer of any email.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            If you wish to have your data completely erased from ConvertKit,
            please email us so we can file an erasure request on your behalf.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We use <StyledA href="https://sparkloop.app">Sparkloop</StyledA> for
            our newsletter referral program. As such, they are connected in
            ConvertKit and also have access to your email address (which is how
            they count your referrals).
          </Typography>
          <Subheading>For Community Members</Subheading>
          <br />
          <Typography variant="h6" component="p" paragraph>
            For community members, the terms of the website also apply, because
            our community and website are integrated together. Here are some
            additional details for community members.
          </Typography>
          <Typography variant="h5" component="p" paragraph>
            Data we collect
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We collect your name and email address, in order to create your
            community and website profiles.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We don't share this information with any third parties, with the
            exception of the products listed below (ConvertKit, Circle, Zapier,
            and Airtable).
          </Typography>
          <Typography variant="h5" component="p" paragraph>
            Third-Party Services
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We use <StyledA href="https://circle.so">Circle</StyledA> as our
            community platform. Please review their privacy policy and terms and
            conditions when you create your community account.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We use <StyledA href="https://convertkit.com">ConvertKit</StyledA>{' '}
            as our email service provider. As such, your email address and
            sometimes your name (if you provide it) is stored with them. If you
            wish to unsubscribe from the newsletter, you can do so using the
            link in the footer of any email.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We use <StyledA href="https://stripe.com">Stripe</StyledA> for
            payment processing and subscription management. We never store or
            have access to your credit card information.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We use <StyledA href="https://zapier.com">Zapier</StyledA> to
            automate syncing our list of Circle community members and our
            ConvertKit email list. As such, your name and email address may pass
            through Zapier as it connects to other services. Zapier does not
            store this data.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We use <StyledA href="https://mailgun.com">Mailgun</StyledA> to
            email you about any replies to your comments. The email server we
            use with Mailgun is hosted in the EU.
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We use <StyledA href="https://airtable.com">Airtable</StyledA> and{' '}
            <StyledA href="https://www.typeform.com/" target="_blank">
              TypeForm
            </StyledA>{' '}
            sometimes to collect feedback or conduct surveys. You may choose
            optionally to share your name and email address in these surveys so
            we can follow up with you about the results.
          </Typography>
          <Typography variant="h5" component="p" paragraph>
            How your data is stored
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            Your data is stored in Google Firebase as a primary data-source.
            It's replicated to third-party data stores, specifically ConvertKit
            via Zapier.
          </Typography>
          <Typography variant="h5" component="p" paragraph>
            Cookies
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            We use cookies to keep you signed in to the website and community.
          </Typography>
          <Subheading>Your rights</Subheading>
          <br />
          <Typography variant="h5" component="p" paragraph>
            Your rights
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            You have the right to access, rectify, erase, restrict processing,
            object to processing, or export personal data you share with us. If
            you would like to exercise these rights, please contact me.
          </Typography>
          <Typography variant="h5" component="p" paragraph>
            Authority
          </Typography>
          <Typography variant="h6" component="p" paragraph>
            Berlin Data Protection Authority located at Friedrichstrasse 219 in
            Berlin, Germany
          </Typography>
          <Subheading>Community Terms and Conditions</Subheading>
          <br />
          <Typography variant="h6" component="p" paragraph>
            By joining our community, you agree to:
          </Typography>
          <Typography variant="h6" component="ol" paragraph>
            <li>
              <strong>Abide by our Code of Conduct.</strong> If you violate the
              code of conduct, I reserve the right to remove you from the
              community and cancel your membership and subscription.
            </li>
            <li>
              <strong>
                Have your data shared with a small number of essential tools.
              </strong>{' '}
              We don't do retargeting or share your email with ad networks. By
              joining the community, you agree that your name and email may be
              stored in a small number of tools we use to run the community,
              such as Circle and ConvertKit.
            </li>
            <li>
              <strong>Usage terms.</strong> You agree not to share your login
              information with other people or attempt to share member benefits
              with non-community members.
            </li>
            <li>
              <strong>Payment and subscription terms.</strong> Your payment
              cycle begins the day you become a member of the community. We have
              a 14-day, no questions asked, money-back guarantee. I reserve the
              right to change prices and subscriptions in the future, but if
              this would affect you, you'd be informed well in advance.
            </li>
            <li>
              <strong>Hold Harmless.</strong> I take the accuracy of this
              website and community resources seriously. Still, it's provided
              without any warranty of any kind, expressed or implied. We're not
              responsible for any damages if you apply advice or information
              provided by us, or another community member.
            </li>
          </Typography>
          <br />
          <Subheading>Questions about this document?</Subheading>
          <br />
          <Typography variant="h6" component="p" paragraph>
            Feel free to send an email! If you have clarifying questions, I'm
            happy to share more details with you.
          </Typography>
          <br />
          <Subheading>Last updated: 15 May, 2021</Subheading>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default IndexPage;
